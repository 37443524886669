@import "../../index.scss";

.mainContainer {
  background: #1f2a40;
  color: #fff;
  font-family: $font-family;

  .header-content {
    padding: 12px;
    display: flex;
    justify-content: space-between;
  }

  .pro-inner-item {
    :hover {
      background-color: #868dfb !important;
    }
  }

  .pro-inner-item {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;
    :hover {
      background-color: #868dfb;
    }
  }

  .content-profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    margin-bottom: 20px;
    gap: 12px;

    img {
      width: 70px;
      border-radius: 50%;
    }

    p {
      font-family: $font-family;
    }
  }
}
