@import "../../index.scss";

section#sign {
  font-family: $font-family;
  background: rgb(26, 32, 56);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: white;
    flex-flow: row wrap;
    display: flex;
    max-width: 650px;
    padding: 12px;
    height: 400px;
    border-radius: 10px;
    justify-content: space-between;

    .image-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      height: 100%;
      background-color: rgb(250, 250, 250);
    }

    img {
      width: 200px;
    }

    .form-login {
      padding: 24px;
      width: 350px;
      align-self: center;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input {
        padding: 12px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
    }
    button {
      border: none;
      border-radius: 3px;
      width: 56px;
      height: 30px;
      background-color: rgb(25, 118, 210);
      padding: 6 16px;
      color: white;
    }
  }

  @media screen and (max-width: 660px) {
    .container {
      flex-direction: column;
      height: 90%;
      width: 90%;
      justify-content: space-evenly;

      .image-content {
        width: 100%;
        height: 200px;
      }

      img {
        width: 100%;
      }

      .form-login {
        width: 100%;
        padding: 12px;
      }
    }
  }
}
