@import "../../index.scss";

section#dashboard {
  padding: 12px;
  font-family: $font-family;
  h3 {
    font-size: 24px;
  }

  select {
    width: 290px;
    height: 35px;

    option {
      font-size: 14px;
    }
  }

  .search-konten {
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
      font-size: 14px;
      font-weight: 500;
    }

    .text-search {
      display: flex;
    }

    .search-icon {
      display: flex;
      gap: 12px;
    }
  }

  .container-musik {
    display: flex;
    flex-flow: row wrap;
    margin-top: 24px;
  }

  @media screen and (max-width: 668px) {
    .search-konten {
      .search-icon {
        flex-direction: column;
        gap: 12px;
      }
    }
    select {
      width: 100%;
    }
  }
}
